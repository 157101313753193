// COLORS
$color-white: #fff;
$color-eggshell: #FAFAFA;
$color-cloud: #F4F4F4;
$color-divider: #D2D9DC;
$color-storm: #6C7784;
$color-midnight: #282C30;
$color-tuxedo: #131517;
$color-black: #000;

$color-plum-lighter: #F8ECF8;
$color-plum-light: #9B4D9B;
$color-plum-primary: #742F7C;
$color-plum-dark: #52005D;

$color-teal-lighter: #ECF6F8;
$color-teal-light: #39B7D8;
$color-teal-primary: #00809D;
$color-teal-dark: #006E7F;

$color-shamrock-lighter: #F3F8EB;
$color-shamrock-light: #96CE35;
$color-shamrock-primary: #568200;
$color-shamrock-dark: #3A5500;

$color-tangerine-lighter: #FFF5EB;
$color-tangerine-light: #F79448;
$color-tangerine-primary: #DF6D1E;
$color-tangerine-dark: #B04C0E;

$color-raspberry-lighter: #FFF5F8;
$color-raspberry-light: #EF7094;
$color-raspberry-primary: #B21A57;
$color-raspberry-dark: #95113B;

$color-gold-lighter: #FFF8E5;
$color-gold-light: #FFDE82;
$color-gold-primary: #FFCF5C;
$color-gold-dark: #E8B036;

$cm-list-item-hover:  #EDEFF1;


// GLOBAL COMPONENT DEFAULTS AND MIXINS
$default-component-border-radius: 24px;
$default-component-spacing: 24px;
$container-border: 2px solid $color-divider;
$left-box-shadow: -5px 0 5px rgba(0, 0, 0, 0.25);
$right-box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);

@mixin rounded-component-border {
  border-radius: $default-component-border-radius;
  border: $container-border;
}

// TYPOGRAPHY MIXINS
$base-font-size: 16px;

@mixin h1 {
  /* <h1>: 2em (32px if the base size is 16px) */
  font-size: calc(#{$base-font-size} * 2); // 32px
  font-weight: 300;
  line-height: 40px;
}

@mixin h2 {
  /* <h2>: 1.5em (24px if the base size is 16px) */
  font-size: calc(#{$base-font-size} * 1.5); // 24px
}

@mixin h3 {
  /* <h3>: 1.17em (18.72px if the base size is 16px) */
  font-size: calc(#{$base-font-size} * 1.17); // 18.72px
  font-weight: 800;
}

@mixin h4 {
  /* <h4>: 1em (16px if the base size is 16px) */
  font-size: $base-font-size; // 16px
}

@mixin h5 {
  /* <h5>: 0.83em (13.28px if the base size is 16px) */
  font-size: calc(#{$base-font-size} * 0.83); // 13.28px
}

@mixin h6-font-size {
  /* <h6>: 0.67em (10.72px if the base size is 16px) */
  font-size: calc(#{$base-font-size} * 0.67); // 10.72px
}

// UTILITY MIXINS
@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}
