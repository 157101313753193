@use "sass:math";
@import 'variables';
@import 'material-overwrite';

/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import './assets/fonts/feather/feather';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  background-color: $color-eggshell;
  color: $color-tuxedo;
  font-family: 'Nunito Sans', sans-serif;
  font-size: $base-font-size;
  font-weight: 400;
  scroll-behavior: smooth;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: $color-eggshell;
}

button {
  @include center;
  background-color: $color-plum-light;
  border-radius: $default-component-border-radius;
  border: 2px solid $color-plum-dark;
  color: $color-white;
  cursor:pointer;
  font-size: $base-font-size;
  gap: 8px;
  height: 40px;
  padding: 0 $default-component-spacing;

  &.full {
    flex: 1;
  }

  &.disabled {
    color: $color-storm;
    background-color: $color-cloud;
    border-color: $color-divider;
    cursor: not-allowed;
  }

  &.secondary {
    background-color: $color-white;
    border-color: $color-plum-light;
    color: $color-plum-primary;
  }

  &.close {
    background-color: $color-white;
    border-color: $color-raspberry-light;
    color: $color-raspberry-primary;
  }
}

.loading-indicator {
  text-align: -webkit-center;
  padding: 16px;

  h3 {
    padding-top: 16px;
  }
}

@function rem($px, $base: $base-font-size) {
  @return math.div($px, $base) * 1rem;
}
