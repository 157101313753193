// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@import 'variables';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$dark-primary-text: $color-tuxedo;
$light-primary-text: white;

// Customize the mat $red-palette with some of our colors
$campaign-manager-warn-palette: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: $color-raspberry-primary,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #b71c1c,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (
    50: $dark-primary-text,
    100:  $dark-primary-text,
    200:  $dark-primary-text,
    300:  $dark-primary-text,
    400:  $dark-primary-text,
    500:  $light-primary-text,
    600:  $light-primary-text,
    700:  $light-primary-text,
    800:  $light-primary-text,
    900:  $light-primary-text,
    A100:  $dark-primary-text,
    A200:  $light-primary-text,
    A400:  $light-primary-text,
    A700:  $light-primary-text,
  )
);

$campaign-manager-web-primary: mat.define-palette(mat.$indigo-palette);
$campaign-manager-web-accent: mat.define-palette(mat.$purple-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$campaign-manager-web-warn: mat.define-palette($campaign-manager-warn-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$campaign-manager-web-theme: mat.define-light-theme(
  (
    color: (
      primary: $campaign-manager-web-primary,
      accent: $campaign-manager-web-accent,
      warn: $campaign-manager-web-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($campaign-manager-web-theme);

/* Start dropdown styles */
.mat-mdc-select-arrow {
  color: $color-plum-primary;
}

.mat-mdc-select-placeholder {
  font-style: italic;
}
/* End dropdown styles */

/* Start datepicker styles */
.mat-mdc-icon-button.mat-mdc-button-base {
  color: $color-plum-primary;
  margin-top: -15px;
}
/* End datepicker styles */

/* Start text field styles */
.input .mat-mdc-text-field-wrapper {
  height: 40px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 10px;
  padding-bottom: 10px;
}

.mdc-text-field .mdc-notched-outline .mdc-notched-outline__trailing {
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.mdc-text-field .mdc-notched-outline .mdc-notched-outline__leading {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  width: 20px !important;
}

.mdc-notched-outline__leading,
.mdc-notched-outline__notch,
.mdc-notched-outline__trailing {
  border-width: 2px !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--invalid) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--invalid) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--invalid) .mdc-notched-outline__trailing {
  border-color: $color-divider;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: $color-storm;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: $color-storm;
}
/* End text field styles */

/* Start checkbox field styles */
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #742F7C; //$color-plum-primary
  --mdc-checkbox-selected-hover-icon-color: #742F7C; //$color-plum-primary
  --mdc-checkbox-selected-icon-color: #742F7C; //$color-plum-primary
  --mdc-checkbox-selected-pressed-icon-color: #742F7C; //$color-plum-primary
  --mdc-checkbox-unselected-focus-icon-color: #52005D; //$color-plum-dark
  --mdc-checkbox-unselected-hover-icon-color: #52005D; //$color-plum-dark
  --mdc-checkbox-unselected-icon-color: #742F7C; //$color-plum-primary
  --mdc-checkbox-unselected-pressed-icon-color: #742F7C; //$color-plum-primary
}
/* End checkbox field styles */

/* Start dialog styles */
.mat-mdc-dialog-container .mat-mdc-dialog-surface.mdc-dialog__surface {
  border-radius: 24px;
}
/* End dialog styles */
